$(document).ready(function()
{
 detectBrowser();
});

function detectBrowser()
{
 let getBrowserInfo = function()
 {
  let ua= navigator.userAgent, tem,
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if(/trident/i.test(M[1]))
  {
   tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
   return 'IE '+(tem[1] || '');
  }

  if(M[1]=== 'Chrome')
  {
   tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
   if(tem!= null)
    return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null)
   M.splice(1, 1, tem[1]);
  return M.join(' ');
 };

 if(getBrowserInfo().substr(0,2)=='IE')
 {
  swal({
   html: true,
   title: "<h5>¡Atención!</h5>",
   text: '<h6 class="text-justify">Estas usando Internet Explorer, este navegador no es compatible con algunas funciones del sitio web, por favor utiliza otro navegador para una mejor experiencia, preferiblemente Google Chrome o Firefox</h6>',
   type: "error",
   closeOnCancel: false,
   closeOnConfirm: false,
   confirmButtonClass: "btn--outline-danger",
   confirmButtonText: "Aceptar",
  });
 }
}
